<template>
    <div class="loading-indicator">
        <img
            src="../assets/animations/loader.gif"
            height="100"
            width="100"
            alt="Loading"
        >
        <h5
            class="fw-light title"
            v-html="title"
        />
        <p
            v-if="subTitle"
            class="text-muted text-center"
            v-html="subTitle"
        />
    </div>
</template>

<script>
    export default {
        name: 'LoadingIndicator',
        props: {
            title: String,
            subTitle: String,
        },
        methods: {
            handleAnimation: function (anim) {
                this.anim = anim
            },

            stop: function () {
                this.anim.stop()
            },

            play: function () {
                this.anim.play()
            },

            pause: function () {
                this.anim.pause()
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/loadingIndicator.scss';
</style>
